import { captureException, User, withScope } from '@sentry/nextjs'

interface CaptureSentryErrorOptions {
  extras?: Record<string, unknown>
  tags?: Record<string, string>
  user?: User
}

/**
 * Captures an exception with Sentry, attaching additional context.
 *
 * @param message - A descriptive message for the error.
 * @param error - The Error object to capture.
 * @param options - Optional settings including extras, tags, and user info.
 */
export const captureSentryError = (
  err?: unknown,
  message?: string,
  options?: CaptureSentryErrorOptions
) => {
  if (err instanceof Error) {
    withScope((scope) => {
      // Attach message and error information
      scope.setExtra('message', message)
      if (err) {
        scope.setExtra('errorMessage', err.message)
        scope.setExtra('errorStack', err.stack)
      }

      // Provide any extra information needed to help debug the problem
      if (options?.extras) {
        Object.entries(options.extras).forEach(([key, value]) => {
          scope.setExtra(key, value)
        })
      }

      // Provide tags that can be used for filtering out certain errors
      if (options?.tags) {
        Object.entries(options.tags).forEach(([key, value]) => {
          scope.setTag(key, value)
        })
      }

      // Provide more user info like id and email
      if (options?.user) {
        scope.setUser(options.user)
      }

      captureException(err || message)
    })
  } else {
    captureException(new Error(message ?? 'Unknown error occurred'))
  }
}
