'use client'

import { RefreshCw } from 'lucide-react'

import { Button } from '../Button'

interface RateLimitErrorViewProps {
  retryAfter: string
  onRefresh: () => void
}

export function RateLimitErrorView({
  retryAfter,
  onRefresh,
}: RateLimitErrorViewProps) {
  return (
    <div className='flex h-fit w-full flex-1 flex-col justify-center m-auto gap-2 sm:max-w-2xl'>
      <div className='flex flex-col items-center bg-white p-10 md:rounded-3xl bg-opacity-60 gap-4'>
        <div className='text-center space-y-6'>
          <h1 className='text-3xl'>Rate Limit Exceeded</h1>
          <p className='text-md text-gray-600 leading-relaxed'>
            You have reached the maximum number of requests. Please wait{' '}
            {retryAfter} before trying again. The page will automatically
            refresh when you can continue.
          </p>
          <div className='flex flex-col sm:flex-row gap-4 justify-center pt-4'>
            <Button
              color='light'
              onClick={onRefresh}
              className='w-full sm:w-auto'
            >
              <RefreshCw className='h-4 w-4' />
              Try again
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
